<template>
	<div class="py-6">
		<h4 class="font-bold">
			{{ userName }}
		</h4>
		<div>
			{{ formattedTime }}
		</div>
		<WwReadMore
			v-if="review"
			keep-trigger-on-bottom
			@is-expanded="setExpanded"
		>
			<template #default="{ hideTrigger }">
				<WwHtmlContent
					class="text-left"
					:markup="review"
					:expanded="expanded"
					use-line-clamp
					@hide-trigger="hideTrigger"
				/>
			</template>
			<template #footer>
				<div class="flex items-center pr-2 text-black">
					<StarRating
						v-if="isMounted"
						:rating="rating"
						:increment="0.1"
						:read-only="true"
						:star-size="15"
						:padding="6"
						:show-rating="false"
						class="pb-1"
					/>
					<span>
						{{ rating }}
					</span>
				</div>
			</template>
		</WwReadMore>
	</div>
</template>

<script async>
import WwHtmlContent from '@/components/UI/WwHtmlContent.vue'
import WwReadMore from '@/components/UI/WwReadMore.vue'
import { formatUnixDateTime } from '@/utils/formatUnixTime.js'

export default {
	components: {
		WwReadMore,
		WwHtmlContent,
		StarRating: () => import('vue-star-rating')
	},
	props: {
		reviewContent: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			expanded: false,
			isMounted: false
		}
	},
	computed: {
		userName() {
			return this.reviewContent?.user?.displayName || this.reviewContent.user?.username || ''
		},
		formattedTime() {
			if (this.reviewContent?.creationTime) return formatUnixDateTime(this.reviewContent.creationTime)
			return ''
		},
		review() {
			if (this.reviewContent?.review) return `<p>${this.reviewContent.review}</p>`
			return ''
		},
		rating() {
			return this.reviewContent?.overallRating
		},
		triggerText() {
			return this.expanded ? 'View Less' : 'Read More'
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		setExpanded(isExpanded) {
			this.expanded = isExpanded
		}
	}
}
</script>
